<template>
  <header class="LoginLogoCenter">
      <div class="header-center">
        <LoginLogo />
      </div>
  </header>
</template>

<script>
import LoginLogo from "./components/LoginLogo";
export default {
  name: "LoginHeader",
  components: {
    LoginLogo
  },
};
</script>

<style lang="scss" scoped>
.LoginLogoCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background-color: $color-primary;
  height: 60px;
  padding: 0 10px;
  z-index: 99;
  width: 100%;
  .header-center{
    width: 1200px;
    margin: 0 auto;
    line-height: 60px;
  }
}
</style>
